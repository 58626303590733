<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="tip">{{ $t("salaryWelfare.SW_tipOne") }}</div>
      <div class="SWmodular">
        <div class="SWmodularItem SWmodularItem0">
          <div class="dian"></div>
          <div>
            {{ $t("salaryWelfare.SW_modular")[0] }}
          </div>
        </div>
        <div class="SWmodularItem SWmodularItem1">
          <div class="dian"></div>
          <div>{{ $t("salaryWelfare.SW_modular")[1] }}</div>
        </div>
        <div class="SWmodularItem">
          <div class="dian"></div>
          <div>{{ $t("salaryWelfare.SW_modular")[2] }}</div>
        </div>
        <!-- <div class="SWmodularItem SWmodularItem2">
          <div class="dian"></div>
          <div v-html="$t('salaryWelfare.SW_modular')[3]"></div>
        </div> -->
        <div class="SWmodularItem SWmodularItem1 SWmodularItem0">
          <div class="dian"></div>
          <div v-html="$t('salaryWelfare.SW_modular')[4]"></div>
        </div>
        <div class="SWmodularItem">
          <div class="dian"></div>
          <div v-html="$t('salaryWelfare.SW_modular')[5]"></div>
        </div>
        <div class="SWmodularItem SWmodularItem1">
          <div class="dian"></div>
          <div v-html="$t('salaryWelfare.SW_modular')[6]"></div>
        </div>
      </div>
    </div>

    <div class="outerTwo">
      <div class="tipTwo">{{ $t("salaryWelfare.SW_tipTwo") }}</div>
      <div class="TDmodular">
        <div v-for="(item, index) in TDlist" :key="index" class="TDmodularItem">
          <div class="itemImg">
            <img :src="item.image" alt="" class="imgHoverBig" />
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：人才招聘-薪酬福利 培训与发展
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-15 09:37
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "人才招聘",
          title_en: "Talent recruitment",
          path: "recruitment",
        },
        {
          title_zh: "薪酬福利 培训发展",
          title_en: "Salary welfare training development",
          path: "",
        },
      ],
      TDlist: "",
    };
  },
  mounted() {
    this.getTraining();
  },
  methods: {
    /**
     * 培训与发展
     * 刘嘉鑫
     * 2022-8-19
     */
    getTraining() {
      this.$request({
        url: "/Index/peixList",
        data: {},
      }).then((res) => {
        console.log("培训发展", res);
        this.TDlist = res.peix_list.peix;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 60px 310px 20px;
  background: #f6f6f6;
  .tip {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #1a2a60;
    margin-bottom: 40px;
  }
  .SWmodular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;

    .SWmodularItem {
      width: 23%;
      padding: 20px 0 61px;
      background: #ffffff;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #1a2a60;
      margin-bottom: 40px;
    }
    .SWmodularItem0 {
      width: 48.5%;
    }
    .SWmodularItem1 {
      background: #1a2a60;
      color: #ffffff;
    }

    .SWmodularItem2 {
      padding: 20px 0 50.4px !important;
    }

    .SWmodularItem2 .dian {
      margin-bottom: 55px !important;
    }

    .SWmodularItem1 .dian {
      background: #1a2a60 !important;
      border: 4px solid #ffffff !important;
    }

    .dian {
      background: #ffffff;
      border: 4px solid #1a2a60;
      border-radius: 50%;
      margin-bottom: 68px;
      display: inline-block;
      width: 3px;
      height: 3px;
    }
  }
}

.outerTwo {
  background: #1a2a60;
  padding: 60px 310px;

  .tipTwo {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 60px;
  }

  .TDmodular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .TDmodularItem {
      width: 48.5%;
      height: 310px;
      position: relative;
      margin-bottom: 60px;

      > .title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 18px;
        background: linear-gradient(
          0deg,
          rgba(26, 42, 96, 0.8) 0%,
          rgba(26, 42, 96, 0.4) 100%
        );
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
      }

      .itemImg {
        overflow: hidden;
        // width: 48.5%;
        // height: 310px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>